
import { defineComponent } from 'vue';
import ListComponent from './components/ListComponent.vue';
import NavBar from './components/NavBarComponent.vue';
/* eslint-disable */

export default defineComponent({
  name: 'App',
  components: {
    ListComponent,
    NavBar,
  },
  data() {
    return {
      result: {
        files: [],
        buttonText: '',
        buttonUrl: '',
      },
      status: 'loading',
      guid: ''
    };
  },
  async mounted() {
    await this.getUrlParams();
    this.getFiles();
  },

  methods: {
    async getFiles() {
      try {
      const response = await fetch(
        `https://varmdo-share-files-api.azurewebsites.net/api/get-files?guid=${this.guid}`
      );
      const files = await response.json();
      if(response.status == 400) {
        this.status = 'failed-invalid';
        return;
      }
      if(response.ok) {
        this.status = 'done';
        this.result = files;
      } 
    } catch(error) {
      console.log(error);
      this.status = 'failed';
    }
    },
    getUrlParams() {
      let urlParams = new URLSearchParams(window.location.search);
      if(urlParams.has('guid')) {
        // eslint-disable-next-line
        const guid = urlParams.get('guid') || '';
        this.guid = guid;
      }
    },
  },
});
