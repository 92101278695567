import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileComponent = _resolveComponent("FileComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files.files, (file) => {
      return (_openBlock(), _createBlock(_component_FileComponent, {
        file: file,
        key: file.name,
        onClick: ($event: any) => (_ctx.openFile(file.url))
      }, null, 8, ["file", "onClick"]))
    }), 128))
  ]))
}